export default {
  heading: 'Roboto Slab, serif',
  body: 'Oswald, sans-serif',
  monospace: 'Roboto Slab, monospace',
  display: 'Roboto Slab, serif',
  display2: 'Roboto Slab, sans-serif',
  display3: 'Roboto Slab',
  googleFonts: ['Oswald:100,200,300,400,500,600,700,800,900', 'Roboto Slab:100,200,300,400,500,600,700,800,900'],
  customFamilies: ['Almond Regular', 'Launsela Regular', 'Pesto'],
  customUrls: [
    // 'https://www.gonation.biz/fonts/almond-regular/almond-regular.css',
    // 'https://www.gonation.biz/fonts/launsela-regular/launsela-regular.css',
    // 'https://www.gonation.biz/fonts/pesto/pesto.css',
  ]
}
