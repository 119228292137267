export default {
  text: '#000002',
  primary: '#C62A37',
  secondary: '#000',
  tertiary: '#C62A37',
  background: '#f7f7f7',
  backgroundSecondary: '#9796A9',
  light: '#9796A9',
  dark: '#0C0A08'
}
